<template>
  <section class="container mx-auto py-6 md:py-10">
    <h2 class="section-heading sm:w-full mx-auto sm:mx-0">
      We Drive Our Clients to Digital Transformation
    </h2>
    <div
      class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-5 md:gap-x-10 lg:gap-x-[100px] gap-y-3 pt-8"
    >
      <template v-if="isLoading">
        <UtilsSkeletonLoading
          v-for="i in 18"
          :key="i"
          class="w-full h-[100px]"
        />
      </template>

      <template v-else>
        <div
          v-for="client in clientsData"
          :key="client.id"
          class="client-img flex justify-center"
        >
          <NuxtImg
            v-if="client.attributes?.image?.data?.attributes?.url"
            format="webp"
            srcset=""
            preset="client"
            class="w-full h-[100px] object-contain"
            loading="lazy"
            :src="client.attributes?.image?.data?.attributes?.url"
            :alt="client.attributes?.name || 'Clients logo'"
            :title="client.attributes?.name || 'Clients logo'"
          />
        </div>
        <template v-if="isLoadingMore">
          <UtilsSkeletonLoading
            v-for="i in 18"
            :key="i"
            class="w-full h-[100px]"
          />
        </template>
      </template>
    </div>
    <div
      v-if="clientsMeta?.pagination?.page < clientsMeta?.pagination?.pageCount"
      class="mx-auto text-center mt-12"
    >
      <button
        class="px-3 py-[10px] sm:py-3 sm:px-5 text-sm sm:text-base border border-[#FF6700] rounded-md text-[#FF6700] block w-max mx-auto hover:bg-[#FF6700] hover:text-white"
        @click="loadMore"
      >
        Load More
      </button>
    </div>
  </section>
</template>

<script setup>
// VARIABLE DECLARATION
const isLoading = ref(true)
const isLoadingMore = ref(false)

const page = ref(1)
const clientsData = ref([])
const clientsMeta = ref({})

// METHODS
const getClients = async () => {
  const { data, error, pending } = await useSuitmediaApiData('/api/clients', {
    query: {
      'pagination[page]': page,
      'pagination[pageSize]': 18,
      'populate[image][fields][0]': 'url',
      'fields[0]': 'id',
      'fields[1]': 'name',
      'filters[is_homepage][$eq]': 'true',
      'sort[0]': 'name'
    }
  })

  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Ideas does not exist`
    })
  }

  return data.value
}

const loadMore = async () => {
  isLoadingMore.value = true
  page.value++
  const addClients = await getClients()
  clientsData.value = [...clientsData.value, ...addClients.data]
  clientsMeta.value = addClients.meta
  isLoadingMore.value = false
}

// FIRST LOAD DATA
const clients = await getClients()

clientsData.value = clients.data
clientsMeta.value = clients.meta
</script>

<style lang="scss" scoped></style>
